<template>
  <div class="login_container_verificaition">
    <!-- 登录 -->
    <div class="login">
      <!-- title  -->
      <h3 class="title">{{ translateTitle("安全验证") }}</h3>

      <!-- tab切换 -->
      <div class="tab-change">
        <el-tabs v-model="currentType" class="tabs" @tab-click="onTabChange">
          <el-tab-pane
            v-for="item in validList"
            :key="item.key"
            :name="item.key"
          >
            <span slot="label">{{ translateTitle(item.title) }}</span>
          </el-tab-pane>
        </el-tabs>
      </div>

      <!-- 表单 -->
      <div class="login-form">
        <el-form
          ref="loginForm"
          :rules="formRules"
          :model="loginData"
          label-position="top"
          @submit.native.prevent
        >
          <!-- 发送验证码 可以直接用 -->
          <el-form-item prop="code" class="verification-code">
            <div class="verification">
              <InputNumber
                v-if="currentType == 0"
                v-model="loginData.code"
                autocomplete="off"
                :placeholder="translateTitle('请输入谷歌验证码')"
                maxlength="6"
                ref="google"
              ></InputNumber>
              <InputNumber
                v-if="currentType == 1"
                v-model="loginData.code"
                autocomplete="off"
                style="width:400px"
                :placeholder="translateTitle('请输入邮箱验证码')"
                maxlength="6"
                ref="email"
              ></InputNumber>
              <Code
                class="count-down"
                v-show="currentType == 1"
                :counting.sync="emailCounting"
                @count="onEmailCode"
                :text="translateTitle('获取验证码')"
              ></Code>
              <InputNumber
                v-if="currentType == 2"
                v-model="loginData.code"
                autocomplete="off"
                :placeholder="translateTitle('请输入手机验证码')"
                maxlength="6"
                ref="mobile"
              ></InputNumber>
              <Code
                class="count-down"
                v-show="currentType == 2"
                :counting.sync="mobileCounting"
                @count="onMobileCode"
                :text="translateTitle('获取验证码')"
              ></Code>
              <!-- <div class="count-down">
                <span
                  v-if="
                    mobileCountDown.countDown === 0 ||
                    mobileCountDown.countDown === -1
                  "
                  @click="fetchCode"
                  >获取验证码</span
                >
                <span v-else>{{ mobileCountDown.countDown + "s" }}</span>
              </div> -->
            </div>
          </el-form-item>
          <!-- 登录 -->
          <el-button
            class="login-btn theme-btn"
            type="primary"
            :loading="loginLoading"
            @click="onLogin"
            >{{ translateTitle("确认") }}</el-button
          >
          <div class="sercurity-title">
            <span class="sercurity" @click="onSercuity">{{
              translateTitle("安全项丢失?")
            }}</span>
          </div>
        </el-form>
      </div>
    </div>
    <!-- 版权 -->
    <!-- <div class="copyright">
      <span>Copyright &copy; 2021, Topcredit All Rights Reserved</span>
    </div> -->
  </div>
</template>
<script>
import Code from "./components/Code";
import InputNumber from "@/components/common/InputNumber";
import { showMessage } from "@/components/dialog/message";
import * as Account from "@/model/user/account";
import {
  sendSignSmsCode,
  sendSignEmailCode,
  loginSendSmsCode,
  loginSendEmailCode,
} from "@/model/user/security";
import { translateTitle } from "@/assets/js/i18n";
import { mapGetters } from "vuex";
import routeSplicing from "@/assets/js/routeSplicing";
export default {
  data() {
    return {
      loginLoading: false, // 登录loading
      currentType: 0, // 当前选项
      loginData: {
        // 登录数据
        code: "",
      },
      formRules: {
        code: [
          { required: true, validator: this.loginValidator, trigger: "blur" },
          {
            pattern: /^\d{6}$/,
            message: this.translateTitle(`请输入正确的6位验证码`),
            trigger: "blur",
          },
        ],
      },
      accountInfo: {},
      authInfo: {},
      validList: [],
      mobileCounting: false,
      emailCounting: false,
    };
  },
  created() {
    if (this.$route.params && this.$route.params.authInfo) {
      let {
        accountInfo,
        authInfo,
        authInfo: { open_google, open_email, open_mobile },
      } = this.$route.params;
      this.accountInfo = accountInfo;
      this.authInfo = authInfo;
      if (open_google == 1) {
        this.validList.push({
          key: "0",
          title: this.translateTitle(`谷歌`),
        });
      }
      if (open_email == 1) {
        this.validList.push({
          key: "1",
          title: this.translateTitle(`邮箱`),
        });
      }
      if (open_mobile == 1) {
        this.validList.push({
          key: "2",
          title: this.translateTitle(`手机号`),
        });
      }
      this.currentType = this.validList[0].key;
    } else {
      this.$router.go(-1);
      return;
    }
    document.onkeyup = e => {
      let keycode = document.all ? event.keyCode : e.which;
      // 在登录界面 且 按得键是回车键enter
      if (
        // [
        //   this.routeSplicing(this.language, "login/mobile"),
        //   this.routeSplicing(this.language, "login/email")
        // ].includes(this.$route.path) &&
        ["/login/mobile", "/login/email"].includes(this.$route.path) &&
        keycode === 13
      ) {
        this.onLogin();
      }
    };
  },
  mounted() {
    this.initFocus();
  },
  computed: {
    ...mapGetters({
      language: "user/language",
    }),
  },
  watch: {
    loginData: {
      handler(val) {
        if (val.code.length == 6) {
          this.onLogin();
        }
      },
      deep: true,
    },
  },
  methods: {
    routeSplicing,
    translateTitle,
    initFocus() {
      if (this.currentType == 0) {
        this.$refs.google.onFocus();
      } else if (this.currentType == 1) {
        this.$refs.email.onFocus();
      } else if (this.currentType == 2) {
        this.$refs.mobile.onFocus();
      }
    },
    onSercuity() {
      this.$router.push(this.routeSplicing(this.language, "loginSecurity"));
    },
    onMobileCode() {
      let tempToken = sessionStorage.getItem("topcredit_exchange_t");
      const data = {
        type: 1,
        token: tempToken,
      };
      loginSendSmsCode(data).then(rs => {
        if (rs.status == 200) {
          this.mobileCounting = true;
          showMessage({
            message: this.translateTitle(`发送成功`),
            type: "success",
          });
          this.$refs.mobile.onFocus();
        }
      });
    },
    onEmailCode() {
      let tempToken = sessionStorage.getItem("topcredit_exchange_t");
      const data = {
        type: 31,
        token: tempToken,
      };
      loginSendEmailCode(data).then(rs => {
        if (rs.status == 200) {
          this.emailCounting = true;
          showMessage({
            message: this.translateTitle(`发送成功`),
            type: "success",
          });
          this.$refs.email.onFocus();
        }
      });
    },
    // 登录校验
    loginValidator(rule, value, callback) {
      let { currentType } = this;
      let msg = "";
      if (currentType == 0) {
        msg = this.translateTitle(`请输入正确的6位验证码`);
      } else if (currentType == 1) {
        msg = this.translateTitle(`请输入邮箱验证码`);
      } else if (currentType == 2) {
        msg = this.translateTitle(`请输入手机验证码`);
      }
      if (value === "" || !value) {
        callback(new Error(msg));
      } else {
        callback();
      }
    },
    /* 切换tab */
    onTabChange({ name }) {
      this.currentType = name;
      this.$refs.loginForm.resetFields();

      // this.$nextTick(() => {
      //   if (name == 0) {
      //     this.$refs.google.onFocus();
      //   } else if (name == 1) {
      //     this.$refs.email.onFocus();
      //   } else if (name == 2) {
      //     this.$refs.mobile.onFocus();
      //   }
      // });
    },
    /* 获取请求参数 */
    getParams() {
      let { currentType } = this;
      let params = { ...this.loginData };
      let tempToken = sessionStorage.getItem("topcredit_exchange_t");
      params.token = tempToken;
      switch (currentType) {
        case "0":
          params.google_code = params.code;
          break;
        case "1":
          params.email_code = params.code;
          break;
        case "2":
          params.mobile_code = params.code;
          break;
      }
      delete params.code;
      return params;
    },
    /* 登录点击 */
    onLogin() {
      let { currentType } = this;
      this.$refs.loginForm.validate(async valid => {
        if (valid) {
          let result;
          let params = this.getParams();
          this.loginLoading = true;
          if (currentType == 0) {
            result = await Account.googleVerifyLogin(params);
          } else if (currentType == 1) {
            result = await Account.emailVerify(params);
          } else if (currentType == 2) {
            result = await Account.smsVerify(params);
          }
          this.handLoginSuccess(result.data, result.status, result.msg);
          this.loginLoading = false;
        }
      });
    },
    /* 登录成功|失败处理 */
    async handLoginSuccess(data, status, msg) {
      if (status !== 200) {
        showMessage({ message: msg ? msg : status });
        return;
      }
      showMessage({
        message: this.translateTitle(`登录成功`),
        type: "success",
      });
      //写入cookie, 邮箱登录 , 手机登录  注册一致
      await Account.writeUserInfo(data, true);
      this.$store.dispatch("user/getUserInfo").then(res => {
        let jump = this.$route.params.jump;
        if (jump) {
          //by TZ bakUrl貌似又有问题了。我这里解决的是bakUrl=true/false的问题。
          location.href = jump;
          return;
        } else {
          this.redirectBasedAutInfo(res.auth);
        }
      });
    },
    // 根据认证信息重定向

    redirectBasedAutInfo(info = {}) {
      let jump = this.$route.fullPath.split("redirect=")[1] || "";
      if (jump) {
        jump = decodeURIComponent(jump);
      } else {
        this.$router.push({ path: "/" });
      }
    },
  },
  components: { InputNumber, Code },
};
</script>
<style lang="scss">
.login_container_verificaition {
  width: 100%;
  min-width: 1260px;
  height: calc(100vh - 60px);
  text-align: left;
  background-image: linear-gradient(174deg, #19273c 12%, #273244 92%);
  background-size: 100% 100%;
  min-height: 600px;
  box-sizing: border-box;
  padding-top: 70px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently

  /* 登录 */
  .login {
    width: 500px;
    min-height: 268px;
    margin: 120px auto 0;
    background: rgba(255, 255, 255, 1);
    border-radius: 4px;
    position: relative;
    z-index: 5;
    .el-input__inner {
      height: 48px;
    }

    h3 {
      color: #354052;
      font-size: 20px;
      font-weight: 500;
      padding: 23px 31px 29px;
      padding-bottom: 0;
      text-align: center;
    }

    .tab-change {
      height: 54px;
      margin-top: 24px;
      display: flex;
      justify-content: left;
      padding-left: 60px;

      .tabs {
        .el-tabs__nav-wrap {
          &:after {
            background-color: transparent;
          }
          /* 选项 */
          .el-tabs__item {
            color: #333;
            font-size: 16px;
            font-weight: normal;
          }
          /* 底部条 */
          .el-tabs__active-bar {
            background: #ffffff;
            content: "";

            &:after {
              content: "";
              height: 3px;
              width: 20px;
              background-color: #c61b3d;
              position: absolute;
              bottom: 0px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }

    /* 表单 */
    .login-form {
      padding: 0 50px;
      .el-button--primary {
        border-color: #c61b3d;
      }
      .el-form {
        input {
          border-radius: 2px;
          &:focus {
            border-color: #c61b3d;
          }
        }

        /* 每一项 */
        .el-form-item {
          padding-top: 3px;
        }

        /* 获取验证码 */
        .verification-code {
          margin-top: 0px;
          &.is-error {
            .verification .number .el-input__inner {
              border-color: #c61b3d;
            }
          }
          .verification {
            position: relative;
            display: flex;
            .number {
              flex: 1;
              .el-input__inner {
                border: 1px solid #dcdfe6;
              }
            }
            .sendcode {
              margin-left: 6px;
            }
          }
        }

        /* 登录 */
        .login-btn {
          width: 100%;
          height: 48px;
          color: #fff;
          font-size: 16px;
          line-height: 48px;
          text-align: center;
          border-radius: 2px;
          background-image: linear-gradient(264deg, #ff486d 10%, #c61b3d 40%);
          border: 0;
          padding: 0;
          cursor: pointer;
          &:hover {
            background-image: linear-gradient(264deg, #ff486d 10%, #c61b3d 40%);
          }
        }
      }
    }
  }
  .sercurity-title {
    padding: 20px 0px 30px;
    text-align: center;
    .sercurity {
      color: #3348f6;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
    }
  }
  .count-down {
    width: 80px;
    color: #3348f6;
    line-height: 1em;
    text-align: center;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    .disabled {
      color: #9aa1b1;
    }

    span {
      cursor: pointer;
    }
  }

  /* 版权 */
  .copyright {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 64px;
    font-size: 14px;
    text-align: center;
    color: #fff;
  }
  input::-moz-placeholder {
    /* Firefox 19+ */
    color: #c2c7d0 !important;
    font-weight: 400;
    font-size: 14px;
  }
  input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #c2c7d0 !important;
    font-weight: 400;
    font-size: 14px;
  }

  input:-ms-input-placeholder {
    /* IE 10+ */
    color: #c2c7d0 !important;
    font-weight: 400;
    font-size: 14px;
  }

  input:-moz-placeholder {
    /* Firefox 18- */
    color: #c2c7d0 !important;
    font-weight: 400;
    font-size: 14px;
  }
}
</style>
